/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './afk-guide.scss';

const AFKGuidesCrystal: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page afk-journey afk-guide'}
      game="afk"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/afk-journey/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Dream Realm - Crystal Beetle</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/afk/categories/category_crystal.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>AFK Journey Crystal Beetle</h1>
          <h2>
            Dream Realm Crystal Beetle guide that explains the boss mechanics
            and how to beat it!
          </h2>
          <p>
            Last updated: <strong>13/09/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Crystal Beetle " />
        <StaticImage
          src="../../../images/afk/generic/boss_beetle.webp"
          alt="Guides"
        />
        <p>
          <strong>Crystal Beetle</strong> is one of the 8 available Dream Realm
          bosses you will encounter in AFK Journey who was introduced to the
          game in Season 2. In this guide we will go over the boss mechanics and
          the best characters and teams to use against him.
        </p>
        <SectionHeader title="What’s the meta team?" />
        <p>
          Realistically speaking,{' '}
          <strong>
            your meta team will depend on your specific box, and your account
            focus
          </strong>
          , so use your highest rarity DPS carry characters, along with whoever
          can best boost their damage.{' '}
          <strong className="gold">
            Try running Dual carry, as currently there is no way to boost a
            Hypercarry’s damage high enough to make it worth running only one
          </strong>
          . Keep faction bonus in mind but don’t run suboptimal characters just
          to force it.
        </p>
        <p>
          Still, below you can find a few example teams. Big thanks to{' '}
          <StaticImage
            src="../../../images/afk/icons/afkanalytica.png"
            alt="AFK Analytica"
          />{' '}
          <strong className="yellow">AFK Analytica</strong> for the team
          building tool. You can find their website{' '}
          <a
            href="https://www.afkanalytica.com/"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>{' '}
          and their discord{' '}
          <a
            href="https://www.discord.gg/analytica"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
        <SectionHeader title="Season 2 Teams (Pre-Endless)" />
        <p>
          In Season 2 it will take a few weeks to unlock Endless mode and due to
          how True Damage works pre-Endless (it being weaker than normal
          damage), the meta will most likely look slightly different compared to
          the established Season 1 teams. Also, Season 2 comes with brand new
          Artifacts that also can have a big impact on the meta teams.
        </p>
        <p>
          We will add the teams that work best for us every rotation here soon!
        </p>
        {/* <Tabs
          defaultActiveKey="Team1"
          transition={false}
          id="boss-tabs"
          className="nav-tabs"
        >
          <Tab
            eventKey="Team1"
            title={
              <>
                <FontAwesomeIcon icon={faSkull} width="18" />
                Team #1 - Best Team (Whale) Endless
              </>
            }
            className="with-bg"
          >
            <h5 className="endless">
              <FontAwesomeIcon icon={faSkull} width="18" /> Team #1 - Best Team
              (Whale) Endless
            </h5>
            <StaticImage
              src="../../../images/afk/dream/bear_1.webp"
              alt="Guides"
            />
            <p>
              This team can be used both pre-Endless and once you reach the
              difficulty.
            </p>
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="lily-may" enablePopover /> is
                the main carry,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="phraesto" enablePopover />{' '}
                acts as the tank, main carry, and provides energy,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="ludovic" enablePopover /> is
                the secondary carry and healer,
              </li>
              <ul>
                <li>
                  <AFKCharacter mode="inline" slug="reinier" enablePopover />{' '}
                  can be used as an alternative to Ludovic.
                </li>
              </ul>
              <li>
                <AFKCharacter
                  mode="inline"
                  slug="smokey-and-meerky"
                  enablePopover
                />{' '}
                acts as healer, buffer and secondary damage dealer,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="koko" enablePopover /> acts as
                shielder and buffer/debuffer - her shields are really important
                to survive against the boss as Smokey alone won't be able to
                keep the team alive,
              </li>
              <li>Artifacts:</li>
              <ul>
                <li>
                  <AFKItem name="Evocation Spell" /> best in slot for the
                  defensive and offensive buffs
                </li>
              </ul>
            </ul>
          </Tab>
          <Tab
            eventKey="Team2"
            title={
              <>
                <FontAwesomeIcon icon={faSkull} width="18" />
                Team #2 - Second Best Team (Whale, No Ludovic) Endless
              </>
            }
            className="with-bg"
          >
            <h5 className="endless">
              <FontAwesomeIcon icon={faSkull} width="18" /> Team #2 - Second
              Best Team (Whale, No Ludovic) Endless
            </h5>
            <StaticImage
              src="../../../images/afk/dream/bear_2.webp"
              alt="Guides"
            />
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="lily-may" enablePopover /> is
                the main carry,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="phraesto" enablePopover />{' '}
                acts as the tank, main carry, and provides energy,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="reinier" enablePopover /> acts
                as buffer and debuffer (<strong>Mythic+</strong> required),
              </li>
              <ul>
                <li>
                  <AFKCharacter mode="inline" slug="mikola" enablePopover />
                  can be used as an alternative to Reinier.
                </li>
              </ul>
              <li>
                <AFKCharacter
                  mode="inline"
                  slug="smokey-and-meerky"
                  enablePopover
                />{' '}
                acts as healer, buffer and secondary damage dealer,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="koko" enablePopover /> acts as
                shielder and buffer/debuffer - her shields are really important
                to survive against the boss as Smokey alone won't be able to
                keep the team alive,
              </li>
              <li>Artifacts:</li>
              <ul>
                <li>
                  <AFKItem name="Evocation Spell" /> best in slot for the
                  defensive and offensive buffs
                </li>
              </ul>
            </ul>
          </Tab>
          <Tab
            eventKey="Team3"
            title={
              <>
                <FontAwesomeIcon icon={faSkull} width="18" />
                Team #3 - Alt Team Endless
              </>
            }
            className="with-bg"
          >
            <h5 className="endless">
              <FontAwesomeIcon icon={faSkull} width="18" /> Team #3 - Alt Team
              Endless
            </h5>
            <StaticImage
              src="../../../images/afk/dream/bear_3.webp"
              alt="Guides"
            />
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="lily-may" enablePopover /> is
                the main carry,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="marilee" enablePopover /> is
                the secondary carry,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="reinier" enablePopover /> acts
                as buffer and debuffer (<strong>Mythic+</strong> required),
              </li>
              <li>
                <AFKCharacter mode="inline" slug="lucius" enablePopover /> is
                used for his shields + seasonal buff
              </li>
              <li>
                <AFKCharacter
                  mode="inline"
                  slug="smokey-and-meerky"
                  enablePopover
                />{' '}
                acts as healer, buffer and secondary damage dealer,
              </li>
              <li>Artifacts:</li>
              <ul>
                <li>
                  <AFKItem name="Evocation Spell" /> best in slot for the
                  defensive and offensive buffs
                </li>
              </ul>
            </ul>
          </Tab>
          <Tab
            eventKey="Team4"
            title={
              <>
                <FontAwesomeIcon icon={faSkull} width="18" />
                Team #4 - No Celehypo Endless
              </>
            }
            className="with-bg"
          >
            <h5 className="endless">
              <FontAwesomeIcon icon={faSkull} width="18" /> Team #4 - No
              Celehypo Endless
            </h5>
            <StaticImage
              src="../../../images/afk/dream/bear_4.webp"
              alt="Guides"
            />
            <p>Character information:</p>
            <ul className="bigger-margin">
              <li>
                <AFKCharacter mode="inline" slug="marilee" enablePopover /> is
                the main carry,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="odie" enablePopover /> is the
                secondary carry,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="korin" enablePopover /> is the
                secondary carry and is also used for his shields ,
              </li>
              <li>
                <AFKCharacter
                  mode="inline"
                  slug="smokey-and-meerky"
                  enablePopover
                />{' '}
                acts as healer, buffer and secondary damage dealer,
              </li>
              <li>
                <AFKCharacter mode="inline" slug="koko" enablePopover /> acts as
                shielder and buffer/debuffer - her shields are really important
                to survive against the boss as Smokey alone won't be able to
                keep the team alive,
              </li>
              <li>Artifacts:</li>
              <ul>
                <li>
                  <AFKItem name="Evocation Spell" /> best in slot for the
                  defensive and offensive buffs
                </li>
              </ul>
            </ul>
          </Tab>
        </Tabs> */}
        <SectionHeader title="Boss Mechanics" />
        <p>Coming soon!</p>
        <SectionHeader title="Tips and tricks" />
        <p>Coming soon!</p>
        <SectionHeader title="Videos" />
        <p>Coming soon!</p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AFKGuidesCrystal;

export const Head: React.FC = () => (
  <Seo
    title="Crystal Beetle | AFK Journey | Prydwen Institute"
    description="Dream Realm Crystal Beetle guide that explains the boss mechanics and how to beat it!"
    game="afk"
  />
);
